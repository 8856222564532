import {
  getGridSingleSelectOperators,
  getGridDateOperators,
  getGridStringOperators,
  GridActionsCellItem,
} from '@mui/x-data-grid-pro'
import {
  Edit,
  Preview,
  CloudDownload,
  CloudUpload,
  ContentPasteOff,
  AssignmentTurnedIn,
  Delete,
} from '@mui/icons-material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'

import { useDialogState } from '@tabeeb/shared/utils/hooks'
import {
  CertificateScopeType,
  CertificateScopeTypeDisplayName,
  CertificateStatusType,
  CertificateStatusTypeDisplayName,
  TenantPermission,
} from '@tabeeb/enums'
import { hasTenantPermission } from '@tabeeb/modules/permissions/selectors'
import { readUploadedFileAsDataUrl } from '@tabeeb/modules/fileUploads/services'
import { getMe } from '@tabeeb/modules/account/selectors'
import { ConfirmationDialog } from '@tabeeb/shared/dialogs'
import { mimeTypes } from '@tabeeb/shared/uikit/constants'
import usePdfViewer from '@tabeeb/shared/uikit/hooks/usePdfViewer'
import CertificateStatusChip from '@tabeeb/modules/certificates/components/CertificateStatusChip'
import GenericDataGrid from '../GenericDataGrid'
import useCertificatesGrid from '../../hooks/useCertificatesGrid'
import AddEditCertificateDialog from '../AddEditCertificateDialog'
import BetweenDatesCustomFilterOperator from '../BetweenDatesCustomFilterOperator'
import CertificateTypeCustomFilterOperator from '../CertificateTypeCustomFilterOperator'
import UserCustomGridFilterOperator from '../UserCustomGridFilterOperator'
import ViewCertificateDialog from '../ViewCertificateDialog'
import { getReviewAllowed } from '../../helpers'
import CertificatesImageViewer from '../CertificatesImageViewer'
import useGenericDataGrid from '../../hooks/useGenericDataGrid'
import GridUserListItem from '../GridUserListItem'
import CertificatesPageHeader from '../CertificatesPage'
import { userDeletedDisplayText } from '../../constants'
import LocationGridFilterOperator from '../LocationGridFilterOperator'

const betweenDateFilterCustomOperator = {
  label: 'between',
  value: 'between',
  InputComponent: BetweenDatesCustomFilterOperator,
}

const checkBoxFilterOperators = getGridSingleSelectOperators().filter(({ value }) => value === 'isAnyOf')
const acceptableDateFilterOperators = ['after', 'before']
const dateFilterOperators = getGridDateOperators().filter(({ value }) => acceptableDateFilterOperators.includes(value))
dateFilterOperators.push(betweenDateFilterCustomOperator)
const ownerFilterOperator = getGridStringOperators()
  .filter(({ value }) => value === 'contains')
  .map((operator) => ({ ...operator, InputComponent: UserCustomGridFilterOperator }))

const CertificatesGrid = ({ boxSx, contractorSourceTenantId, user, personal = false, simpleView = false }) => {
  const currentUser = useSelector(getMe)

  const filterValueFormatter = useCallback((filter) => {
    return filter.field === 'TypeName'
      ? JSON.stringify({
          ...filter.value,
          typeIds: filter.value?.typeIds?.map((_) => _.Id),
          scope: null,
          scopes: filter.value?.scope ? [filter.value.scope] : [],
        })
      : filter.field === 'Owner'
      ? JSON.stringify(filter.value.map((u) => ({ Id: u.Id, IdentityGuid: u.IdentityGuid })))
      : filter.field === 'Location'
      ? JSON.stringify(filter.value.map((u) => u.Id))
      : JSON.stringify(filter.value)
  }, [])

  const {
    setSortModel,
    filterModel,
    setFilterModel,
    search,
    setSearch,
    rows,
    setRows,
    rowCountState,
    setRowCountState,
    paginationModel,
    payload,
    onDefaultErrorMutation,
    onDefaultSuccessMutation,
  } = useGenericDataGrid({ filterValueFormatter })

  const getCertificatesParams = useMemo(
    () => ({
      ...payload,
      ...(Boolean(contractorSourceTenantId) && { contractorSourceTenantId }),
      ...(Boolean(user?.IdentityGuid) &&
        Boolean(user?.Id) && {
          userGuid: user.IdentityGuid,
          userId: user.Id,
        }),
    }),
    [payload, contractorSourceTenantId, user?.IdentityGuid, user?.Id]
  )
  const {
    getCertificatesResponse,
    getCertificatesLoading,
    abortGetCertificatesRequest,
    onGetCertificatesMutated,
    getAllCertificateCategoriesResponse,
    abortGetAllCertificateCategoriesRequest,
    getAllCertificateTypesResponse,
    getAllCertificateTypesLoading,
    abortAllCertificateTypesRequest,
    updateCertificateLoading,
    updateCertificateMutate,
    createCertificateLoading,
    createCertificateMutate,
    reviewCertificateLoading,
    reviewCertificateMutate,
    reRequestAll,
    setCertificateTypesSearch,
    deleteCertificateLoading,
    deleteCertificateMutate,
    loadingGetUsers,
    responseGetUsers,
    getLocationsResponse,
    getLocationsLoading,
    abortLocationRequest,
  } = useCertificatesGrid({
    getCertificatesParams,
    personalCertificates: personal,
  })

  useEffect(() => {
    return () => {
      abortGetCertificatesRequest()
      abortAllCertificateTypesRequest()
      abortGetAllCertificateCategoriesRequest()
      abortLocationRequest()
    }
  }, [
    abortGetCertificatesRequest,
    abortAllCertificateTypesRequest,
    abortGetAllCertificateCategoriesRequest,
    abortLocationRequest,
  ])

  const certificateTypeCustomFilterOperator = useMemo(
    () => ({
      label: 'type is',
      value: 'typeIs',
      InputComponent: CertificateTypeCustomFilterOperator,
      InputComponentProps: {
        getAllCertificateTypesResponse,
        loading: getAllCertificateTypesLoading,
        personal,
        allCategories: getAllCertificateCategoriesResponse,
        setCertificateTypesSearch,
      },
    }),
    [
      getAllCertificateCategoriesResponse,
      getAllCertificateTypesLoading,
      getAllCertificateTypesResponse,
      personal,
      setCertificateTypesSearch,
    ]
  )

  const locationFilterOperator = useMemo(
    () => ({
      label: 'is any of',
      value: 'isAnyOf',
      InputComponent: LocationGridFilterOperator,
      InputComponentProps: { getLocationsResponse, loading: getLocationsLoading },
    }),
    [getLocationsLoading, getLocationsResponse]
  )

  useEffect(() => {
    setRows(getCertificatesResponse.Certificates)
    setRowCountState(getCertificatesResponse.TotalCount)
  }, [getCertificatesResponse.Certificates, getCertificatesResponse.TotalCount, setRowCountState, setRows])

  const onSucceededMutation = useCallback(
    (message) => {
      onDefaultSuccessMutation(message)
      onGetCertificatesMutated()
    },
    [onDefaultSuccessMutation, onGetCertificatesMutated]
  )

  // view dialog
  const [viewDialogOpen, onViewDialogOpen, onViewDialogClose] = useDialogState()
  const [certificateIdToView, setCertificateIdToView] = useState(null)

  const onReviewCertificateSubmit = useCallback(
    async ({ id, statusId }) => {
      await reviewCertificateMutate(
        { id, statusId },
        {
          onSuccess: () => onSucceededMutation('Certificate was successfully reviewed'),
          onError: () => onDefaultErrorMutation('Failed to review certificate'),
        }
      )
    },
    [onDefaultErrorMutation, onSucceededMutation, reviewCertificateMutate]
  )

  // add edit dialog
  const [addEditDialogOpen, onAddEditDialogOpen, onAddEditDialogClose] = useDialogState()
  const [certificateToEditId, setCertificateToEditId] = useState(null)

  const onAddEditDialogSubmit = useCallback(
    async ({ values, editMode }) => {
      onAddEditDialogClose()
      let photo
      if (values.Photo) {
        try {
          const result = await readUploadedFileAsDataUrl(values.Photo)
          photo = { PhotoName: values.Photo.name, PhotoData: result }
        } catch (e) {
          console.log(`readUploadedFileAsDataUrl ended with error: ${e}`)
        }
      }

      delete values.Photo
      values = {
        ...values,
        ...photo,
        ValidFrom: new Date(values.ValidFrom).toDateString(),
        ExpirationDate: new Date(values.ExpirationDate).toDateString(),
      }
      if (editMode) {
        await updateCertificateMutate(
          { ...values },
          {
            onSuccess: () => onSucceededMutation('Certificate was successfully updated'),
            onError: () => onDefaultErrorMutation('Failed to update certificate'),
          }
        )
      } else {
        await createCertificateMutate(values, {
          onSuccess: () => onSucceededMutation('Certificate was successfully created'),
          onError: () => onDefaultErrorMutation('Failed to create certificate'),
        })
      }
    },
    [
      createCertificateMutate,
      onAddEditDialogClose,
      onDefaultErrorMutation,
      onSucceededMutation,
      updateCertificateMutate,
    ]
  )

  const hasManageCertificatesTenantScopePermission = useSelector((state) =>
    hasTenantPermission(state, TenantPermission.CertificatesTenantScope)
  )
  const hasManageCertificatesGlobalScopePermission = useSelector((state) =>
    hasTenantPermission(state, TenantPermission.CertificatesGlobalScope)
  )

  // actions
  const handleEditClick = useCallback(
    (certToEdit) => {
      setCertificateToEditId(certToEdit.Id)
      onAddEditDialogOpen()
    },
    [onAddEditDialogOpen]
  )

  const handleViewClick = useCallback(
    (certToViewId) => {
      setCertificateIdToView(certToViewId)
      onViewDialogOpen()
    },
    [onViewDialogOpen]
  )

  const handleSendToReviewClick = useCallback(
    async (certificate) => {
      await updateCertificateMutate(
        {
          ...certificate,
          StatusId: CertificateStatusType.PendingReview,
          LocationIds: certificate.Locations.map((l) => l.Id),
        },
        {
          onSuccess: () => onSucceededMutation('Certificate was successfully sent to review'),
          onError: () => onDefaultErrorMutation('Failed to send certificate to review'),
        }
      )
    },
    [onDefaultErrorMutation, onSucceededMutation, updateCertificateMutate]
  )

  const handlePickUpFromReviewClick = useCallback(
    async (certificate) => {
      await updateCertificateMutate(
        { ...certificate, StatusId: CertificateStatusType.Draft, LocationIds: certificate.Locations.map((l) => l.Id) },
        {
          onSuccess: () => onSucceededMutation('Certificate was successfully picked up from review'),
          onError: () => onDefaultErrorMutation('Failed to pick up the certificate from review'),
        }
      )
    },
    [onDefaultErrorMutation, onSucceededMutation, updateCertificateMutate]
  )

  const handleDeleteClick = useCallback(
    async (id) => {
      await deleteCertificateMutate(
        { id },
        {
          onSuccess: () => onSucceededMutation('Certificate was successfully deleted'),
          onError: () => onDefaultErrorMutation('Failed to delete certificate'),
        }
      )
    },
    [deleteCertificateMutate, onDefaultErrorMutation, onSucceededMutation]
  )

  const loading = useMemo(
    () =>
      getCertificatesLoading ||
      updateCertificateLoading ||
      createCertificateLoading ||
      reviewCertificateLoading ||
      deleteCertificateLoading,
    [
      createCertificateLoading,
      getCertificatesLoading,
      updateCertificateLoading,
      reviewCertificateLoading,
      deleteCertificateLoading,
    ]
  )

  const [reviewConfirmationDialogOpen, onReviewConfirmationDialogOpen, onReviewConfirmationDialogClose] =
    useDialogState()
  const [{ id, statusId }, setReviewConfirmation] = useState({ id: 0, statusId: 0 })

  const [openImageViewer, onOpenImageViewer, onCloseImageViewer] = useDialogState()
  const [certificateCoverSrc, setCertificateCoverSrc] = useState('')

  const [numberOfPdfPages, setNumberOfPdfPages, pageNumber, nextPageClick, previousPageClick, setInitialPage] =
    usePdfViewer()

  const pdfViewerProps = useMemo(
    () => ({
      numberOfPdfPages,
      setNumberOfPdfPages,
      nextPageClick,
      previousPageClick,
      pageNumber,
      setInitialPage,
    }),
    [nextPageClick, numberOfPdfPages, pageNumber, previousPageClick, setInitialPage, setNumberOfPdfPages]
  )

  return (
    <>
      <CertificatesPageHeader
        boxSx={boxSx}
        loading={loading}
        reRequestAll={reRequestAll}
        onAddEditDialogOpen={onAddEditDialogOpen}
        isHidden={simpleView}
      >
        <GenericDataGrid
          setSortModel={setSortModel}
          paginationModel={paginationModel}
          filterModel={filterModel}
          gridRows={rows}
          loading={loading}
          setSearch={setSearch}
          search={search}
          rowCountState={rowCountState}
          setFilterModel={setFilterModel}
          disableColumnSelector
          columnVisibilityModel={{
            CreatedOn: false,
            ScopeId: false,
            Location: false,
            ...((personal || simpleView) && { Owner: false }),
          }}
          valueInputSxProps={{ minWidth: '190px', width: 'unset' }}
          DataGridProProps={{
            onCellDoubleClick: (params, event, details) => {
              handleViewClick(params.row.Id)
            },
            rowHeight: 80,
          }}
          dialogComponent={
            <>
              <AddEditCertificateDialog
                open={addEditDialogOpen}
                onClose={onAddEditDialogClose}
                onSubmit={onAddEditDialogSubmit}
                selectedCertificateId={certificateToEditId}
                onExited={() => {
                  setCertificateTypesSearch('')
                  setCertificateToEditId(null)
                }}
                getAllCertificateTypesResponse={getAllCertificateTypesResponse}
                getAllTypesLoading={getAllCertificateTypesLoading}
                setCertificateTypesSearch={setCertificateTypesSearch}
                getLocationsResponse={getLocationsResponse}
                getLocationsLoading={getLocationsLoading}
              />
              <ViewCertificateDialog
                open={viewDialogOpen}
                onClose={onViewDialogClose}
                onExited={() => {
                  setCertificateTypesSearch('')
                  setCertificateIdToView(null)
                }}
                certificateId={certificateIdToView}
                onReview={onReviewCertificateSubmit}
                personal={personal}
              />
            </>
          }
          gridColumns={[
            {
              field: 'ThumbnailUrl',
              headerName: 'Image',
              align: 'center',
              renderCell: ({ row }) => {
                return (
                  <Box
                    onClick={() => {
                      setCertificateCoverSrc(row.PictureUrl)
                      onOpenImageViewer()
                    }}
                    sx={{ cursor: 'pointer' }}
                  >
                    <input
                      alt='Certificate image preview'
                      title='Open image'
                      style={{ maxWidth: 100, maxHeight: 80, pointerEvents: 'none' }}
                      type='image'
                      src={row.ThumbnailUrl ?? row.PictureUrl}
                    />
                  </Box>
                )
              },
              filterable: false,
              sortable: false,
              width: 120,
            },
            {
              field: 'CreatedOn',
              headerName: 'Created on',
              type: 'dateTime',
              valueGetter: ({ value }) => value && new Date(value),
              filterable: false,
              width: 95,
            },
            { field: 'SerialNumber', headerName: 'Serial number', sortable: false, filterable: false, flex: 1 },
            { field: 'Authority', headerName: 'Authority', sortable: false, filterable: false, flex: 1 },
            {
              field: 'TypeName',
              headerName: 'Type',
              sortable: false,
              flex: 1,
              filterOperators: [certificateTypeCustomFilterOperator],
            },
            { field: 'Location', sortable: false, filterOperators: [locationFilterOperator] },
            {
              field: 'ScopeId',
              headerName: 'Scope',
              sortable: false,
              filterable: false,
              valueFormatter: ({ value }) => CertificateScopeTypeDisplayName[value],
              width: 60,
            },
            {
              field: 'StatusId',
              headerName: 'Status',
              sortable: false,
              type: 'singleSelect',
              valueOptions: Object.values(CertificateStatusType).map((value) => ({
                value,
                label: CertificateStatusTypeDisplayName[value],
              })),
              filterOperators: checkBoxFilterOperators,
              width: 110,
              renderCell: ({ row }) => <CertificateStatusChip statusId={row.StatusId} />,
            },
            {
              field: 'Owner',
              headerName: 'Owner',
              sortable: false,
              flex: 1,
              filterOperators: ownerFilterOperator,
              ...((personal || Boolean(user?.IdentityGuid)) && { filterable: false }),
              renderCell: (params) => {
                const cellUser = responseGetUsers.find((_) => _.IdentityGuid === params.row.OwnerIdentityGuid)
                const primaryText =
                  params.row.ScopeId === CertificateScopeType.TenantCertificate
                    ? `Tenant: ${params.row.TenantName}`
                    : currentUser.TenantId === cellUser?.TenantId
                    ? cellUser?.Name
                    : cellUser
                    ? cellUser?.Email
                    : userDeletedDisplayText
                let secondaryText = ''
                if (
                  params.row.ScopeId !== CertificateScopeType.TenantCertificate &&
                  currentUser.TenantId === cellUser?.TenantId
                ) {
                  secondaryText = cellUser?.Email
                }
                return (
                  <GridUserListItem
                    title={primaryText}
                    primaryText={primaryText}
                    secondaryText={secondaryText}
                    usersLoading={loadingGetUsers}
                  />
                )
              },
            },
            {
              field: 'ValidFrom',
              headerName: 'Valid from',
              type: 'dateTime',
              filterOperators: dateFilterOperators,
              valueGetter: ({ value }) => value && new Date(value),
              valueFormatter: ({ value }) => value && value.toLocaleDateString('en-US'),
              width: 90,
            },
            {
              field: 'ExpirationDate',
              headerName: 'Valid till',
              type: 'dateTime',
              filterOperators: dateFilterOperators,
              valueGetter: ({ value }) => value && new Date(value),
              valueFormatter: ({ value }) => value && value.toLocaleDateString('en-US'),
              width: 90,
            },
            {
              field: 'actions',
              type: 'actions',
              width: 50,
              getActions: ({ _, row }) => [
                ...(!simpleView &&
                (![CertificateStatusType.Draft, CertificateStatusType.Obsolete].includes(row.StatusId)
                  ? false
                  : personal
                  ? true
                  : (row.OwnerProfileId ? row.OwnerProfileId === currentUser.Id : true) &&
                    (row.OwnerIdentityGuid ? row.OwnerIdentityGuid === currentUser.IdentityGuid : true) &&
                    (row.ScopeId === CertificateScopeType.TenantCertificate && row.TenantId
                      ? row.TenantId === currentUser.TenantId
                      : true))
                  ? [
                      <GridActionsCellItem
                        icon={<Edit />}
                        label='Edit'
                        title='Edit'
                        onClick={() => handleEditClick(row)}
                        showInMenu
                      />,
                      <GridActionsCellItem
                        icon={<Delete />}
                        label='Delete'
                        title='Delete'
                        onClick={() => handleDeleteClick(row.Id)}
                        showInMenu
                      />,
                    ]
                  : []),
                ...(((row.ScopeId === CertificateScopeType.TenantCertificate &&
                  row.TenantId === currentUser.TenantId &&
                  (hasManageCertificatesGlobalScopePermission || hasManageCertificatesTenantScopePermission)) ||
                  personal) &&
                !simpleView &&
                [CertificateStatusType.Draft, CertificateStatusType.PendingReview].includes(row.StatusId)
                  ? [
                      row.StatusId === CertificateStatusType.Draft ? (
                        <GridActionsCellItem
                          icon={<CloudUpload />}
                          label='Send for review'
                          title='Send for review'
                          onClick={() => handleSendToReviewClick(row)}
                          showInMenu
                        />
                      ) : (
                        <GridActionsCellItem
                          title='Pick up from review'
                          icon={<CloudDownload />}
                          label='Pick up from review'
                          onClick={() => handlePickUpFromReviewClick(row)}
                          showInMenu
                        />
                      ),
                    ]
                  : []),
                <GridActionsCellItem
                  icon={<Preview />}
                  label='View'
                  title='View'
                  onClick={() => handleViewClick(row.Id)}
                  showInMenu
                />,
                ...(!simpleView &&
                getReviewAllowed({
                  personal,
                  currentUserId: currentUser.Id,
                  currentUserIdentityGuid: currentUser.IdentityGuid,
                  certificateStatusId: row.StatusId,
                  certificateScopeId: row.ScopeId,
                  certificateOwnerProfileId: row.OwnerProfileId,
                  certificateOwnerIdentityGuid: row.OwnerIdentityGuid,
                  hasManageCertificatesTenantScopePermission,
                  hasManageCertificatesGlobalScopePermission,
                })
                  ? [
                      <GridActionsCellItem
                        icon={<ContentPasteOff />}
                        label='Reject'
                        title='Reject'
                        onClick={() => {
                          setReviewConfirmation({ id: row.Id, statusId: CertificateStatusType.Rejected })
                          onReviewConfirmationDialogOpen()
                        }}
                        showInMenu
                      />,
                      <GridActionsCellItem
                        icon={<AssignmentTurnedIn />}
                        label='Approve'
                        title='Approve'
                        onClick={() => {
                          setReviewConfirmation({ id: row.Id, statusId: CertificateStatusType.Approved })
                          onReviewConfirmationDialogOpen()
                        }}
                        showInMenu
                      />,
                    ]
                  : []),
              ],
            },
          ]}
        />
      </CertificatesPageHeader>
      <ConfirmationDialog
        isOpen={reviewConfirmationDialogOpen}
        onCloseDialog={onReviewConfirmationDialogClose}
        onSubmit={() => {
          onReviewConfirmationDialogClose()
          onReviewCertificateSubmit({ id, statusId })
        }}
        title={`Are you sure want to ${statusId === CertificateStatusType.Rejected ? 'reject' : 'approve'} certificate`}
        submitButtonText='Yes'
        cancelButtonText='No'
      />
      <CertificatesImageViewer
        open={openImageViewer}
        onClose={() => {
          onCloseImageViewer()
          setCertificateCoverSrc('')
        }}
        slides={[{ src: certificateCoverSrc }].map((el) => ({
          ...el,
          mimeType: el.src.includes('.pdf') ? mimeTypes.pdf : '',
          download: { url: el.src },
        }))}
        pdfViewerProps={pdfViewerProps}
      />
    </>
  )
}

CertificatesGrid.defaultProps = {
  simpleView: false,
  personal: false,
}

CertificatesGrid.propTypes = {
  boxSx: PropTypes.shape({
    minWidth: PropTypes.string,
  }),
  personal: PropTypes.bool,
  simpleView: PropTypes.bool,
  contractorSourceTenantId: PropTypes.number,
  user: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    IdentityGuid: PropTypes.string.isRequired,
    Email: PropTypes.string.isRequired,
  }),
}

export default CertificatesGrid
