import { createApiActions } from '@tabeeb/modules/shared/utils/actions'
import { createAction } from 'redux-actions'

export const resetState = createAction('RESET_SPLAT_MODEL_STATE')

export const openSplatModelTimeline = createAction('OPEN_SPLAT_MODEL_TIMELINE')
export const closeSplatModelTimeline = createAction('CLOSE_SPLAT_MODEL_TIMELINE')

export const addImageToSplatModel = createAction('ADD_IMAGE_TO_SPLAT_MODEL')
export const addAllPagesToSplatModel = createAction('ADD_ALL_PAGES_TO_SPLAT_MODEL')
export const deleteImageFromSplatModel = createAction('DELETE_IMAGE_FROM_SPLAT_MODEL')
export const updateImagesSplatModel = createAction('UPDATE_IMAGES_SPLAT_MODEL')

export const createSplatModel = createApiActions('CREATE_SPLAT_MODEL')
export const getDefaultPageName = createApiActions('GET_DEFAULT_PAGE_NAME')
